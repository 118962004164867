<template>

  <NuxtLink class="strain" :to="props.link">

    <div class="photo">
      <img loading="lazy" :src="props.coverS ? props.coverS : '/images/product-empty-avatar.png'" :alt="props.nm">

      <div class="rate_seed" v-if="props.rate">
        <span class="star_rate"></span>{{ props.rate }}<span class="max">/{{ props.rateof }}</span>
      </div>
  
    </div>
    <div class="info">
      <div class="name_seed">{{ props.nm }}</div>

      <div class="bottom_info">

        <div class="rw" v-if="props.thc">THC {{ props.thc }}%</div>
        <div class="rw" v-if="props.cbd">CBD {{ props.cbd }}%</div>
        <br v-if="props.thc || props.cbd">
        <div class="rw rw-no" v-if="props.cntHarvests">{{ props.cntHarvests }} {{ $t('universal_plurals_harvests') }}</div>

      </div>
    </div>
    
  </NuxtLink>

</template>


<script setup lang="ts">

interface PageProps {
  nm: string;
  coverS: string;
  link: string;
  rate?: number;
  rateof?: number;
  thc?: number;
  cbd?: number;
  cntHarvests?: number;
  cntDiaries?: number;
}

const props = withDefaults(defineProps<PageProps>(), {
  rateof: 10,
});


</script>

<style scoped>
 .strain {
   margin-right: 20px;
   overflow: hidden;
   min-width: 0;
   margin-bottom: 2rem;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   width: calc(100%/7 - 20px);
   contain: layout paint ;
 }

 .group_page .products_list.centered .strain {
   margin: 10px;
   margin-bottom: 2rem;
 }

 @container pb (max-width: 1200px) {
   .strain {
     width: calc(100%/6 - 20px);
   }
 }

 @container pb (max-width: 1100px) {

   .strain {
     width: calc(100%/5 - 20px);
   }
 }

 @container pb (max-width: 1000px) {
   .strain {
     width: calc(100%/5 - 20px);
   }
 }

 @container pb (max-width: 650px) {
   .strain {
     width: calc(100%/3 - 20px);

   }
 }

 @container pb (max-width: 550px) {
   .strain {
     width: calc(50% - 20px);
   }
 }


 .strain .photo {
   width: 100%;
   display: block;
   vertical-align: top;
   position: relative;
   display: flex;
   align-items: center;
   aspect-ratio: 170/237;
   /* margin-top: auto; */
   /* margin-bottom: auto; */
 }

 .strain .type {
   color: gray;
 }

 .strain .photo img {
   width: 100%;
   border-radius: 3px;
 }

 .strain .info {
   display: block;
   /* height: 58px; */
   width: 100%;
   /* background-color: rgba(244, 244, 244, 0.34); */
   border-radius: 0px 0px 3px 3px;
 }

 .strain .info .name_seed {

   font-size: 0.85rem;
   padding-top: 6px;
   font-weight: bold;
   overflow: hidden;
   /* white-space: nowrap; */
   text-overflow: ellipsis;
   text-align: center;
   /* height: 43px; */
   display: flex;
   align-items: center;
   justify-content: center;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
 }

 .strain .info .stat {
   display: none;
 }

 .strain .info .stat .type {
   display: block;
   text-align: left;
 }

 .strain .info .stat .type .type_item {
   color: gray;
   margin-bottom: 6px;
   display: inline-block;
   margin-right: 10px;
   /* float: left; */
 }

 .strain .info .stat .stat_item {
   /* display: inline-block; */
   vertical-align: top;
   /* width: 132px; */
   text-align: center;
   /* float: right; */
   display: block;
   height: 21px;
 }

 .strain .info .stat .stat_item .value {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   /* font-size: 1.4rem; */
   color: #4e4e4e;
   display: inline-block;
 }

 .strain .info .stat .stat_item .name {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   color: gray;
   font-size: 1rem;
   display: inline-block;
   margin-left: 11px;
 }


 .strain .info .stat .stat_item.rate_value .star_rate {
   float: left;
   margin-right: 6px;
   width: 17px;
   height: 17px;
 }

 .strain .info .stat .stat_item.rate_value {
   text-align: left;
 }


 .strain .info .text {
   display: none;
 }

 .strain .small_rate {
   display: block;
   color: gray;
   min-height: 28px;
 }


 .strain .rate_seed {
   color: var(--un-text-color);
   position: absolute;
   bottom: 10px;
   right: 5px;
   width: auto;
   padding: 4px 15px 2px 10px;
   background-color: var(--un-background-color-gray);
   font-weight: bold;
   border-radius: 20px;
   box-shadow: 0px 1px 3px 0px var(--un-element-color-gray), 0px 0px 0px 1px var(--un-element-color-gray);
 }

 .strain .rate_seed .max {
   font-size: 0.85rem;
 }

 .strain .star_rate {
   margin-right: 6px;
   width: 16px;
   display: inline-block;
   vertical-align: top;
   height: 16px;
 }

 .strain .bottom_info {
   display: block;
   width: 100%;
   /* padding-left: 1rem; */
   overflow: hidden;
   white-space: nowrap;
   /* padding-right: 1rem; */
   /* background-color: #f4f4f4; */
   color: #9c9c9c;
   font-size: 0.75rem;
   border-radius: 0px 0px 3px 3px;
   text-align: center;
 }

 .strain .bottom_info .rw:not(:first-child):not(.rw-no)::before {
   content: " • ";
 }

 .strain .bottom_info .rw {
   display: inline-block;
   padding: 0rem 0.3rem 0rem 0rem;
   font-size: 0.75rem;
   color: #9c9c9c;
 }

 .strain .dot {
   display: inline-block;
   width: 3px;
   height: 3px;
   background-color: #b5b5b5;
   margin: 0 6px 0 4px;
   margin-bottom: 2px;
 }
</style>
